import React from "react";
import Index from "../views/Contact";

const ContactUs = () => {
  return (
    <div>
      <Index />
    </div>
  );
};

export default ContactUs;
