import React from "react";
import ContactForm from "./ContactForm";
import { Layout } from "@startuponsale/gatsby-shared-ui";

const Index = () => {
  return (
    <div>
      <Layout>
        <ContactForm />
      </Layout>
    </div>
  );
};

export default Index;
