import React from "react";

const ContactForm = () => {
  return (
    <div className="w-full bg-white">
      <div className="max-w-4xl mx-auto px-4 pt-20 pb-36">
        <h1 className=" text-center text-4xl md:text-5xl font-bold text-dpurple nexabold pb-20">
          Contáctanos para cualquier duda
        </h1>

        <form name="Contact" method="POST" netlify>
          <input type="hidden" name="form-name" value="Contact" />
          <div className="relative w-full mb-4 flex">
            <label htmlFor="firstName" className="text-base nexaTextBold text-dpurple font-normal nexalight pb-2 block w-2/12">
              Nombre:
            </label>
            <input
              type="firstName"
              name="firstName"
              className="py-4 w-full text-sm text-dpurple bg-white border border-dgrey rounded-lg pl-4 focus:outline-none focus:bg-white focus:text-gray-900"
              placeholder="Introduce tu nombre aquí"
              autoComplete="off"
            />
          </div>
          <div className="relative w-full mb-4 flex">
            <label htmlFor="lastName" className="text-base nexaTextBold text-dpurple font-normal nexalight pb-2 block w-2/12">
              Apellidos:
            </label>
            <input
              type="lastName"
              name="lastName"
              className="py-4 w-full  text-sm text-dpurple bg-white border border-dgrey rounded-lg pl-4 focus:outline-none focus:bg-white focus:text-gray-900"
              placeholder="Introduce tus apellidos."
              autoComplete="off"
            />
          </div>
          <div className="relative w-full mb-4 flex">
            <label htmlFor="email" className="text-base nexaTextBold text-dpurple font-normal nexalight pb-2 block w-2/12">
              Email:
            </label>
            <input
              type="email"
              name="email"
              className="py-4 w-full  text-sm text-dpurple bg-white border border-dgrey rounded-lg pl-4 focus:outline-none focus:bg-white focus:text-gray-900"
              placeholder="Tu dirección de email."
              autoComplete="off"
            />
          </div>
          <div className="relative w-full mb-8 flex">
            <label htmlFor="message" className="text-base nexaTextBold text-dpurple font-normal nexalight pb-2 block w-2/12">
              Mensaje:
            </label>
            <textarea
              name="message"
              className="py-4 w-full  text-sm text-dpurple bg-white border border-dgrey rounded-lg pl-4 focus:outline-none focus:bg-white focus:text-gray-900"
              placeholder="Dejános tu mensaje aquí."
              rows="4"
            />
          </div>
          <div className="flex justify-center">
            <button className="text-white text-xl bg-dpink py-4 hover:shadow-xl hover:bg-pink-700 transition-all text-center w-full rounded-lg">
              Enviar Mensaje
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContactForm;
